<template>
  <div class="instore-detail">
    <div class="card">
      <topOperatingButton
        :disabled="disabled"
        :showAudit="showAudit"
        :isAddBtn="showAdd"
        @submitForm="handleSubmit(false)"
        @addBill="handleSubmit(true)"
        @auditBill="changeBillStatus"
        @handleAdd="handleAdd"
        @getQuit="getQuit"
        id="topOperatingButton"
      >
        <template slot="specialDiyBtn">
          <el-button
            size="mini"
            v-if="billType !== '110101' && billType !== '120101' && billType !== '140313'"
            @click="selectBills"
            :disabled="disabled"
            >引入源单
          </el-button>
          <!-- icon="el-icon-switch-button" -->
          <el-button
            icon="el-icon-remove-outline"
            size="mini"
            :disabled="single"
            @click="changeBillStatus('关闭')"
            >终止单据
          </el-button>
        </template>
      </topOperatingButton>

      <!-- 填写明细表单区域 -->
      <el-form
        ref="form"
        :model="form"
        label-width="120px"
        label-position="right"
        class="ken-detail-form"
        :rules="rules"
      >
        <cardTitleCom
          cardTitle="基本信息"
          :billStatus="form.billStatus"
          id="basicInformation"
        >
          <template slot="cardContent">
            <div class="x-f marT10">
              <el-form-item label="单据编号" prop="billNo">
                <el-input
                  v-model="form.billNo"
                  placeholder="单据编号"
                  disabled
                  size="mini"
                ></el-input>
              </el-form-item>

              <el-form-item label="单据日期" prop="billDate">
                <el-date-picker
                  v-model="form.billDate"
                  type="date"
                  placeholder="选择日期"
                  :disabled="disabled"
                  value-format="yyyy-MM-dd"
                  size="mini"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="到货日期" prop="arrivalDate" v-if="['110101', '120101', '140313'].includes(billType)">
                <el-date-picker
                  v-model="form.arrivalDate"
                  type="date"
                  placeholder="选择日期"
                  :disabled="disabled"
                  value-format="yyyy-MM-dd"
                  size="mini"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label=" 供应商 " prop="partnerId" v-if="billType != '140313' && BillSaleType">
                <!-- 供应商自定义下拉框 -->
                <SelectFrame
                  ref="selectFrame"
                  v-model="form.partnerName"
                  :tableData="partnerData"
                  :disabled="disabled || sourceStatus"
                  @getPitchId="getDiyselectId($event, 'partnerId', 'form', '供应商')"
                  :router="'/partner/supplier/newSupplier'"
                  @click="$router.push({ path: '/goods/partner/supplier' })"
                  :normalizer="normalizerSupp"
                  size="mini"
                ></SelectFrame>
              </el-form-item>

              <el-form-item label="客户" prop="partnerId" v-if="!BillSaleType">
                <!-- 客户自定义下拉框 -->
                <SelectFrame
                  ref="selectFrame"
                  v-model="form.partnerName"
                  :tableData="customerData"
                  :disabled="disabled || sourceStatus"
                  @getPitchId="getDiyselectId($event, 'partnerId', 'form', '客户')"
                  :router="'/partner/customer/newCustomers'"
                  @click="$router.push({ path: '/goods/partner/customer' })"
                  :normalizer="normalizerCus"
                  size="mini"
                ></SelectFrame>
              </el-form-item>

              <el-form-item
                label="仓库"
                prop="inStoreId"
                v-if="['140303'].includes(billType)"
              >
                <SelectFrame
                  ref="selectStore"
                  v-model="form.inStoreName"
                  :tableData="storeDataObj"
                  :disabled="disabled"
                  @getPitchId="getDiyselectId($event, 'inStoreId', 'form')"
                  @clickAdd="OpenStoreAdd = true"
                  @click="OpenStore = true"
                  :normalizer="normalizerStore"
                  size="mini"
                ></SelectFrame>
              </el-form-item>
              <el-form-item
                label="仓库"
                prop="storeId"
                v-if="['140313'].includes(billType)"
              >
                <SelectFrame
                  ref="selectStore"
                  v-model="form.storeName"
                  :tableData="storeDataObj"
                  :disabled="disabled"
                  @getPitchId="getDiyselectId($event, 'storeId', 'form')"
                  @clickAdd="OpenStoreAdd = true"
                  @click="OpenStore = true"
                  :normalizer="normalizerStore"
                  size="mini"
                ></SelectFrame>
              </el-form-item>
              <el-form-item
                label="仓库"
                prop="outStoreId"
                v-if="['140307', '140312'].includes(billType)"
              >
                <!-- storeData -->
                <SelectFrame
                  ref="selectStore"
                  v-model="form.outStoreName"
                  :tableData="storeDataObj"
                  :disabled="disabled"
                  @getPitchId="getDiyselectId($event, 'outStoreId', 'form')"
                  @clickAdd="OpenStoreAdd = true"
                  @click="OpenStore = true"
                  :normalizer="normalizerStore"
                  size="mini"
                ></SelectFrame>
              </el-form-item>

              <!-- <el-form-item
                label="仓库"
                prop="outStoreId"
                v-if="storePostType === 'outStoreId'"
              >
                
                <SelectFrame
                  ref="selectStore"
                  v-model="form.outStoreName"
                  :tableData="storeData"
                  :disabled="disabled"
                  @getPitchId="getDiyselectId($event, 'outStoreId', 'form')"
                  @clickAdd="OpenStoreAdd = true"
                  @click="OpenStore = true"
                  :normalizer="normalizerStore"
                  size="mini"
                ></SelectFrame>
              </el-form-item> -->

              <!-- <el-form-item
                label="仓库"
                prop="storeId"
                v-if="storePostType === 'storeId'"
              >
                <SelectFrame
                  ref="selectStore"
                  v-model="form.storeName"
                  :tableData="storeData"
                  :disabled="disabled"
                  @getPitchId="getDiyselectId($event, 'storeId', 'form')"
                  @clickAdd="OpenStoreAdd = true"
                  @click="OpenStore = true"
                  :normalizer="normalizerStore"
                  size="mini"
                ></SelectFrame>
              </el-form-item> -->

              <el-form-item label="手工单据号" prop="handBillNo">
                <el-input
                  v-model="form.handBillNo"
                  :disabled="disabled"
                  placeholder="手工单据号"
                  size="mini"
                  oninput="value=value.replace(/[^0-9a-zA-Z]/g,'')"
                ></el-input>
              </el-form-item>
            </div>

            <div class="x-f">
              <el-form-item
                label="生产工厂"
                v-if="['120101', '140313'].includes(billType)"
                prop="produceDeptId"
              >
                <el-select
                  class="billType marR5"
                  v-model="form.produceDeptId"
                  placeholder="请选择"
                  filterable
                  clearable
                  :disabled="disabled"
                  size="mini"
                >
                  <el-option
                    v-for="item in produceLists"
                    :key="item.produceDeptNo"
                    :label="item.produceDeptName"
                    :value="item.produceDeptId"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="部门" prop="deptId">
                <el-select
                  class="billType marR5"
                  v-model="form.deptId"
                  placeholder="请选择"
                  filterable
                  clearable
                  :disabled="disabled"
                  size="mini"
                >
                  <el-option
                    v-for="item in deptData"
                    :key="item.deptId"
                    :label="item.deptName"
                    :value="item.deptId"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="采购员" prop="purEmployeeId" v-if="BillSaleType">
                <el-select
                  v-model="form.purEmployeeId"
                  placeholder="请选择"
                  :disabled="disabled"
                  @blur="$refs.form.validateField('purEmployeeId')"
                  filterable
                  clearable
                  size="mini"
                >
                  <el-option
                    v-for="item in employeeData"
                    :key="item.employeeId"
                    :label="item.employeeName"
                    :value="item.employeeId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="业务员" prop="saleEmployeeId" v-if="!BillSaleType">
                <el-select
                  v-model="form.saleEmployeeId"
                  placeholder="请选择"
                  :disabled="disabled"
                  @blur="$refs.form.validateField('saleEmployeeId')"
                  filterable
                  size="mini"
                  remote
                  reserve-keyword
                >
                  <el-option
                    v-for="item in employeeData"
                    :key="item.employeeId"
                    :label="item.employeeName"
                    :value="item.employeeId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>

              <!-- <el-form-item label="结算日期" v-if="['120102', '120103', '110102', '110103'].includes(billType)" prop="billDate">
                <el-date-picker
                  v-model="form.settlementDate"
                  type="date"
                  placeholder="选择日期"
                  :disabled="disabled"
                  value-format="yyyy-MM-dd"
                  size="mini"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="结算期限信息" v-if="['120102', '120103', '110102', '110103'].includes(billType)">
                <el-select :disabled="disabled" @change="changeSaleEmployee" v-model="form.settlementTermId" clearable filterable  size="mini" placeholder="请选择">
                  <el-option
                    v-for="item in saleEmployeeData"
                    :key="item.settlementTermId"
                    :label="item.settlementTermName"
                    :value="item.settlementTermId"
                  />
                </el-select>
              </el-form-item> -->

              <el-form-item class="itemAddress" label="收货地址" prop="storeAddress">
                <el-input
                  v-model="form.storeAddressJoin"
                  readonly
                  style="width: 410px"
                  placeholder="请点击右边图标可编辑收货地址"
                  :disabled="disabled"
                  size="mini"
                >
                  <i slot="suffix" class="el-icon-more" @click="setAddress"></i>
                </el-input>
              </el-form-item>
            </div>
          </template>
        </cardTitleCom>

        <!-- 表格商品信息卡片 -->
        <cardTitleCom cardTitle="商品信息" class="headGoodsTable">
          <template slot="rightCardTitle">
            <div class="marR10">
              <el-button
                type="primary"
                v-if="!['140303', '140307', '140312', '140313'].includes(billType)"
                :disabled="disabled"
                size="mini"
                @click="getDistribution()"
                >批量修改仓库</el-button
              >
              <!-- <el-button
                size="mini"
                @click="handleTableColumnConfig()"
                >列配置</el-button
              > -->
            </div>
          </template>
          <template slot="cardContent">
            <div class="instore-table">
              <el-row>
                <el-col :span="24">
                  <keyBoardTable
                    ref="myTable"
                    :key="tableKey"
                    :isDisabled="disabled"
                    :tableHeight="'450px'"
                    :isShowCheckbox="true"
                    :tableColumns="gridColumns"
                    @handleSelectionChange="handleSelectionChange"
                    @handleTableBlur="handleTableBlur"
                    @handleTableSelectChange="handleTableSelectChange"
                    @handleSyncTableData="handleSyncTableData"
                    @handleTableCallback="handleTableCallback"
                    @footerMethod="footerMethod"
                  ></keyBoardTable>
                </el-col>
              </el-row>
            </div>
          </template>
        </cardTitleCom>

        <div class="instore-footer">
          <!-- 附件上传组件 -->
          <accessoryUpload
            listType="img"
            :disabled="disabled"
            :fileList="form.fileItems"
            @getFileItems="getFileItemsData"
            @delFileItems="delFileItems"
          ></accessoryUpload>
          <div class="right-remark">
            <span> 单据备注:</span>
            <el-input
              class="input"
              placeholder="备注"
              :disabled="disabled"
              type="textarea"
              :rows="3"
              v-model="form.billRemark"
            ></el-input>
          </div>
        </div>
      </el-form>
      <!-- 选择商品信息框 -->
      <SelectGoods
        :OpenGoods.sync="OpenGoods"
        :queryCode="queryCode"
        :searchType="searchType"
        :isEnablePurchase="isEnablePurchase"
        :isEnableSale="isEnableSale"
        @getGoodsIds="getGoods($event, true)"
        :extraParams="selectGoodsExtraParams"
      ></SelectGoods>
      <!-- 引入源单框 -->
      <SelectBills
        :title="title"
        :NoFields="NoFields"
        :tableColumnFields="tableColumnFields"
        :billList="billList"
        :openDialog.sync="openBillDia"
        :loadingDia="loadingDia"
        :billTotal="billTotal"
        :detailList="form.details"
        @getSelect="selectBills"
        :allBillList="allBillList"
        @getAllBills="getSelectBills"
        @getBillsIds="getSelectIds($event, true)"
        :billType="billType"
        @search="(searchObj) => selectBills(searchObj)"
      ></SelectBills>
      <!-- 选择供应商对话框 -->
      <selectSupplier
        :openSelectSupplier.sync="openSelectSupplier"
        @getSupplierId="getSupplier($event, 'form')"
      ></selectSupplier>
      <!-- 选择仓库对话框 -->
      <selectStore
        :OpenStore.sync="OpenStore"
        :billType="billType"
        @getStore="getStore($event, 'form', '', 'selectStore')"
      ></selectStore>
      <!-- 新增仓库对话框 -->
      <storeAdd
        :OpenStoreAdd.sync="OpenStoreAdd"
        @getStore="getStore($event, 'form')"
      ></storeAdd>
      <!-- 选择收货地址对话框 -->
      <el-dialog
        v-dialogDrag="true"
        title="收货地址"
        :visible.sync="openAddress"
        width="30%"
        class="AddressDialog"
      >
        <el-form
          ref="AddressForm"
          :model="form.storeAddress"
          v-if="form.storeAddress"
          label-width="80px"
        >
          <el-row>
            <el-col :span="20">
              <el-form-item label="联系人" prop="contactMan">
                <el-input
                  type="text"
                  v-model="form.storeAddress.contactMan"
                  placeholder="请输入联系人"
                  maxlength="30"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="联系电话" prop="contactTel">
                <el-input
                  type="text"
                  v-model="form.storeAddress.contactTel"
                  placeholder="请输入联系电话"
                  maxlength="30"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="地址" prop="storeAddress">
                <el-cascader
                  ref="cascader"
                  class="provinceListClass"
                  :options="provinceList"
                  :props="defaultProp"
                  v-model="form.storeAddress.regionId"
                  maxlength="80"
                  @change="getAddressId"
                  emitPath
                  v-loading="loading"
                >
                </el-cascader>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="详细地址" prop="addressDetail">
                <el-input
                  type="text"
                  v-model="form.storeAddress.addressDetail"
                  placeholder="详细地址"
                  maxlength="30"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="saveAddress">确 定</el-button>
          <el-button @click="openAddress = false">取 消</el-button>
        </div>
      </el-dialog>
    </div>
    <div class="footer" v-if="['110101'].includes(billType)">
      <div class="left"></div>
      <div class="right">
        <div class="item">
          <div class="label">单据金额 :</div>
          <div class="value">{{ billMoney }}</div>
        </div>
      </div>
    </div>
    <!-- 弹窗 -->
    <Dialog ref="tablePage" :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import { getNewDate } from "@/utils/newDate"; //单据日期
import { getBillNo } from "@/api/codeRule";
import { searchItem, detailLayoutPublic } from "@/utils/mixin/pageMixin.js";
import SelectRemote from "@/components/tablePage/select/select-remote/index.vue";
import {
  changeBillDetailType,
  getFileItemsData,
  delFileItems,
  watchScroll,
  scroll,
  destroyScroll,
  scrollEvent,
  savescroll,
  getRegion,
  setAddress,
  setAddressInfo,
  getAddress,
  getAddressId,
  saveAddress,
  getCurTime,
  handleEdit,
  getSummaries,
  // keyup,
  getBasicData,
  // openSelectGoods,
  selectBills,
  getSelectBills,
  reset,
  resetAddress,
  row,
  AddBill,
  submitForm,
  getSupplier,
  getStore,
  getDiyselectId,
  changeUnitPrice,
} from "@/views/components/bill/public-layout.js"; //方法统一存放的地方
import { showFormatting, getNewBillNo } from "@/utils/numFormatting";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import SelectGoods from "@/views/components/SelectGoods/SelectGoods"; //选择商品组件
import SelectBills from "@/views/components/SelectBills/SelectBills"; //选择单据组件
import selectSupplier from "@/views/components/selectSupplier"; //选择供应商组价
import selectStore from "@/views/components/selectStore"; //选择仓库组件
import storeAdd from "@/views/components/selectStore/storeAdd.vue"; //新增仓库组件
import accessoryUpload from "@/views/components/accessoryUpload";
import { updateInstoreStatus } from "@/api/purchase";
import topOperatingButton from "@/views/components/topOperatingButton"; //顶部按钮
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import Dialog from "@/components/Dialog";
import keyBoardTable from "@/components/tablePage/keyBoardTable/index.vue";
import tableColulumnConfig from "@/utils/mixin/tableColumnConfig";
import { arrayObjectCopy } from "@/utils";
//import tableKeydown from "@/utils/mixin/tableKeydown";

export default {
  name: "Detail",
  components: {
    SelectGoods,
    SelectBills,
    Treeselect,
    selectSupplier,
    accessoryUpload,
    selectStore,
    storeAdd,
    cardTitleCom,
    topOperatingButton,
    SelectRemote,
    Dialog,
    keyBoardTable
  },
  mixins: [searchItem, detailLayoutPublic,tableColulumnConfig],
  dicts: ["bill_status"],
  props: {
    billType: {
      required: true,
      type: String,
    },
    searchType: {
      type: Number,
      default: null,
    },
    tableBillSourceCout:{
      type:Number,
      default:0
    },
    tableSpecialCount:{
      type:Number,
      default:0
    }
  },
  data() {
    //自定义校验规则
    const setoodsNo = (rule, value, callback) => {
      if (value === undefined) {
        callback(new Error("请输入商品编码"));
        // this.$message.error('请输入商品编码')
      } else {
        callback();
      }
    };
    const setUnitId = (rule, value, callback) => {
      if (value === undefined) {
        callback(new Error("请选择单位"));
        // this.$message.error('请选择单位')
      } else {
        callback();
      }
    };
    const setUnitQty = (rule, value, callback) => {
      if (value === undefined) {
        callback(new Error("请输入数量"));
      } else {
        callback();
      }
    };
    const setUnitPrice = (rule, value, callback) => {
      if (value === undefined) {
        callback(new Error("请输入单价"));
      } else {
        callback();
      }
    };
    return {
      queryObj: {
        110101: { isEnablePurchase: true, searchType: 1, excludeGoodsTypes: [-1], partnerId: ''},
        110102: { isEnablePurchase: true, excludeGoodsTypes: [-1] },
        110103: { isEnablePurchase: true, excludeGoodsTypes: [-1] },
        120101: { isEnableSale: true, excludeGoodsTypes: [-1] },
        140313: { isEnableSale: true, excludeGoodsTypes: [-1] },
        120102: { isEnableSale: true, excludeGoodsTypes: [-1] },
        120103: { isEnableSale: true, excludeGoodsTypes: [-1] },
        140303: { isEnablePurchase: true, excludeGoodsTypes: [-1] },
        140312: { isEnableSale: true, excludeGoodsTypes: [-1] },
      },
      saleEmployeeData: [], // 结算期限信息
      priceCaseName: "",
      priceCaseId: "",
      optionGoods: {},
      ids: [],
      multiple: true,
      //弹窗配置
      dialogOptions: {
        title: "选择仓库",
        width: 1250,
        show: false,
        type: "TreeAndTable",
      },
      selectGoodsExtraParams: {},
      tableCellLabel: "序号",
      //打印
      PrintObj: {
        id: "print",
      },
      //仓库类型(出库,入库)
      storePostType: undefined,
      //单据类型
      TypeBill: undefined,
      //单据业务类型
      BillSaleType: undefined,
      //区分单价取进价/售价
      unitPriceType: undefined,
      //获取单据详情url
      urlDetail: undefined,
      //修改单据url
      urlUpdate: undefined,
      //新增单据url
      urlAdd: undefined,
      //更新单据状态url
      urlBillStatus: undefined,
      //引入单据url
      urlSourceBill: undefined,
      //过滤供应商的值
      supplierVal: undefined,
      //过滤仓库的值
      storeVal: undefined,
      //仓库业务  区别(采购/销售)业务
      showStoreSale: false,
      //单据id
      billId: undefined,
      //更新订单明细数据变动
      iskey: false,
      //打开选择供应商开关
      openSelectSupplier: false,
      //打开选择仓库开关
      OpenStore: false,
      //打开新增仓库开关
      OpenStoreAdd: false,
      //选择商品信息开关
      OpenGoods: false,
      //传送商品参数
      queryCode: undefined,
      //遮罩层
      loading: false,
      //禁用标识
      disabled: false,
      //标识已审核
      auditOrder: false,
      //标识已关闭
      stopOrder: false,
      //标识未审核
      noAuditOrder: false,
      //标识已删除
      deleteOrder: false,
      //是否启用审核按钮
      showAudit: true,
      //当前表格宽度
      scrollLeft: null,
      //引入源单对话框表格列
      tableColumnFields: [],
      //引入源单框编号字段
      NoFields: [
        { label: "单据编号", name: "billNo" },
        { label: "单据状态", name: "billStatus" },
      ],
      //引入源单框单据数据
      billList: [],
      //引入源单对话框标题
      title: "",
      //引入源单对话框开关
      openBillDia: false,
      //引入源单对话框遮罩层
      loadingDia: false,
      //引入源单单据总数
      billTotal: 0,
      //引入单据全部数据
      allBillList: [],
      //区别(采购/销售)/仓库时,获取收货地址
      diySelectType: true,
      //选择的类型(商品/引入源单)
      selectType: undefined,
      //自定义搜索框字段转换 (供应商)
      normalizerSupp: {
        Id: "supplierId",
        No: "supplierNo",
        Name: "supplierName",
      },
      //自定义搜索框字段转换 (客户)
      normalizerCus: {
        Id: "customerId",
        No: "customerNo",
        Name: "customerName",
      },
      //自定义搜索框字段转换 (仓库)
      normalizerStore: {
        Id: "storeId",
        No: "storeNo",
        Name: "storeName",
      },
      //收货地址对话框
      openAddress: false,
      //收货地址表单数据
      addressForm: {},
      //全部行政地区
      provinceList: [],
      //行政地址类型转换
      defaultProp: {
        value: "id",
        label: "label",
      },
      //省市区
      selectedOptions: [],
      //字典单位数据
      dictUnitData: [],
      //供应商数据
      partnerData: [],
      //客户数据
      customerData: [],
      //仓库数据
      storeData: [],
      storeDataObj: [],
      //员工数据
      employeeData: [],
      //生产工厂
      produceLists: [],
      //部门数据
      deptData: [],
      //区分需要的是(客户/供应商)数据
      getCusSupp: undefined,
      i: {},
      //input保留的小数位数
      decimalNum: 3,
      //表单数据
      form: {
        deptId: undefined, // 门店ID
        //单据id
        billId: undefined,
        //制单日期
        billDate: undefined,
        arrivalDate: undefined,
        //单据编号
        billNo: undefined,
        //供应商
        partnerId: undefined,
        //订单仓库
        storeId: undefined,
        //仓库(入库)
        inStoreId: undefined,
        //仓库(出库)
        outStoreId: undefined,
        //采购员
        purEmployeeId: undefined,
        //单据状态
        billStatus: "",
        //制单人
        createEmployeeId: undefined,
        //审核人
        auditEmployeeId: undefined,
        //审核日期
        auditTime: undefined,
        //单据金额
        billMoney: "00:000",
        //备注
        billRemark: undefined,
        //单据表格-订单明细
        details: [{}],
        //上传的附件
        fileItems: [],
        //收货地址
        storeAddress: {
          contactMan: undefined,
          contactTel: undefined,
          regionId: undefined,
          addressDetail: undefined,
        },
        //收货地址拼接
        storeAddressJoin: undefined,
      },
      fileList: [], // 显示的文件
      isEnablePurchase: false,
      isEnableSale: false,
      //表单校验
      rules: {
        billNo: [{ required: true, message: "请到编码规则设置", trigger: "blur" }],
        billDate: [{ required: true, message: "请输入单据日期", trigger: "blur" }],
        billDate: [{ required: true, message: "请输入单据日期", trigger: "blur" }],
        arrivalDate: [{ required: true, message: "请输入到货日期", trigger: "blur" }],
        billMoney: [{ required: true, message: "请输入单据金额", trigger: "blur" }],
        billNotaxMoney: [
          { required: true, message: "请输入单据不含税金额", trigger: "blur" },
        ],
        billQty: [{ required: true, message: "请输入单据编号", trigger: "blur" }],
        billTaxMoney: [
          { required: true, message: "请输入单据含税金额", trigger: "blur" },
        ],
        partnerId: [
          {
            required: true,
            message: "请选择",
            trigger: ["change", "blur"],
          },
        ],
        purEmployeeId: [{ required: true, message: "请选择采购员", trigger: "change" }],
        saleEmployeeId: [{ required: true, message: "请选择业务员", trigger: "change" }],
        inStoreId: [
          { required: true, message: "请选择仓库", trigger: ["change", "blur"] },
        ],
        storeId: [{ required: true, message: "请选择仓库", trigger: ["change", "blur"] }],
        outStoreId: [
          { required: true, message: "请选择仓库", trigger: ["change", "blur"] },
        ],
        // storeId: [
        //   { required: true, message: '请选择仓库', trigger: ['change', 'blur'] }
        // ],
        "details.storeId": [
          { required: true, message: "请选择仓库", trigger: ["change", "blur"] },
        ],
        "details.goodsNo": [{ validator: setoodsNo, trigger: ["change", "blur"] }],
        "details.unitId": [{ validator: setUnitId, trigger: "change" }],
        "details.unitQty": [{ validator: setUnitQty, trigger: "blur" }],
        "details.unitPrice": [{ validator: setUnitPrice, trigger: "blur" }],
      },
      keepStatus: false,
      //关闭单据
      single: true,
      //新增按钮
      showAdd: false,
      // canKeyTableColumnList:[
      //   {key:'goodsNo',columnindex:2,componentRefName:'remoteSelect'},
      //   {key:'produceDate',columnindex:5,type:'date'},//生产日期
      //   {key:'storeId',columnindex:6,componentRefName:'remoteSelect'},//仓库
      //   {key:'goodsSpec',columnindex:7,type:'select'},
      //   {key:'unitQty',columnindex:8},
      //   {key:'unitId',columnindex:9,type:'select'},
      //   {key:'unitPrice',columnindex:10},
      //   {key:'unitTaxPrice',columnindex:11},
      //   {key:'taxRate',columnindex:12},
      //   {key:'unitPriceTaxMoney',columnindex:14},
      //   {key:'batchNo',columnindex:15},
      //   {key:'attachMoney',columnindex:16},
      //   {key:'remark',columnindex:19}
      // ],//可以键盘导航的表格列详情
      originColumns:[
        //{fieldCaption:'序号',fieldCustomCaption:'序号',fieldName:'index',fieldCustomWidth:60,fieldIsDisplay:true},
        {fieldCaption:'商品编码',fieldCustomCaption:'商品编码',fieldName:'goodsNo',fieldCustomWidth:160,fieldIsDisplay:true,type:'selectRemote',isRequired:true},
        {fieldCaption:'商品名称',fieldCustomCaption:'商品名称',fieldName:'goodsName',fieldCustomWidth:120,fieldIsDisplay:true},
        {fieldCaption:'条码',fieldCustomCaption:'条码',fieldName:'barcode',fieldCustomWidth:160,fieldIsDisplay:true},
        {fieldCaption:'生产日期',fieldCustomCaption:'生产日期',fieldName:'produceDate',fieldCustomWidth:180,type:'date',fieldIsDisplay:true},
        {fieldCaption:'仓库',fieldCustomCaption:'仓库',fieldName:'storeId',fieldCustomWidth:180,fieldIsDisplay:true,type:'selectRemote',isRequired:true,options:this.$select({ key: 'listStore' }).option},
        {fieldCaption:'规格',fieldCustomCaption:'规格',fieldName:'goodsSpec',fieldCustomWidth:120,fieldIsDisplay:true,type:'select',optionListName:'units',optionValue:'unitId',optionLabel:'goodsSpec'},
        {fieldCaption:'数量',fieldCustomCaption:'数量',fieldName:'unitQty',fieldCustomWidth:120,fieldIsDisplay:true,type:'inputNumber',isRequired:true},
        {fieldCaption:'单位',fieldCustomCaption:'单位',fieldName:'unitId',fieldCustomWidth:120,fieldIsDisplay:true,type:'select',optionListName:'units',isRequired:true,optionValue:'unitId',optionLabel:'unitName'},
        {fieldCaption:'单价(元)',fieldCustomCaption:'单价(元)',fieldName:'unitPrice',fieldCustomWidth:120,fieldIsDisplay:true,isRequired:true,type:'inputNumber'},
        {fieldCaption:'含税单价(元)',fieldCustomCaption:'含税单价(元)',fieldName:'unitTaxPrice',fieldCustomWidth:120,fieldIsDisplay:true,type:'inputNumber'},
        {fieldCaption:'税率(%)',fieldCustomCaption:'税率(%)',fieldName:'taxRate',fieldCustomWidth:120,fieldIsDisplay:true,type:'inputNumber'},
        {fieldCaption:'税额',fieldCustomCaption:'税额',fieldName:'unitTaxMoney',fieldCustomWidth:120,fieldIsDisplay:true},
        {fieldCaption:'价税合计(元)',fieldCustomCaption:'价税合计(元)',fieldName:'unitPriceTaxMoney',fieldCustomWidth:120,fieldIsDisplay:true,type:'inputNumber'},
        {fieldCaption:'商品批号',fieldCustomCaption:'商品批号',fieldName:'batchNo',fieldCustomWidth:120,fieldIsDisplay:true,type:'inputNumber'},
        {fieldCaption:'附加费',fieldCustomCaption:'附加费',fieldName:'attachMoney',fieldCustomWidth:120,fieldIsDisplay:true,type:'inputNumber'},
        {fieldCaption:'可退数量',fieldCustomCaption:'可退数量',fieldName:'importUnitQty',fieldCustomWidth:100,fieldIsDisplay:true},
        {fieldCaption:'价格方案',fieldCustomCaption:'价格方案',fieldName:'priceCaseName',fieldCustomWidth:100,fieldIsDisplay:true},
        {fieldCaption:'单据源',fieldCustomCaption:'单据源',fieldName:'billSourceOrder',fieldCustomWidth:100,fieldIsDisplay:true},
        {fieldCaption:'备注',fieldCustomCaption:'备注',fieldName:'remark',fieldCustomWidth:120,fieldIsDisplay:true,type:'inputText'},
      ],//原始列表数据
      originColumnsCopy:[],
      tableKey:Date.now()
    };
  },
  filters: {
    //过滤3位小数,并四舍五入
    numFilter(value) {
      let realVal;
      if (value) {
        realVal = parseFloat(value).toFixed(3);
      } else {
        realVal = "";
      }
      return realVal;
    },
    numFilterFun(value) {
      let realVal;
      if (value) {
        // realVal = parseFloat(value).toFixed(8)
        realVal = Number(value.toFixed(8));
      } else {
        realVal = "";
      }
      return realVal;
    },
  },
  watch: {
    //侦听传过来的单据类型
    billType: {
      handler(newVal) {
        this.handleCalcGridColumn(this.billType,'watch billType');
        if (this.$route.query.type === "Update") {
          if (this.$route.query.billId) {
            this.billId = this.$route.query.billId;
          }
        } else {
          this.$nextTick(() => {
            this.getNewBillNo(this.form, "billNo", Number(this.TypeBill));
          });
        }
        this.getTableList(newVal);
      },
      immediate: true,
    },
    "form.partnerName": {
      handler(newVal) {
        // console.log(newVal, 'newVal');
        // console.log(this.billType, 'this.billType');
        if (['110101', 110101, '110102', 110102, '120101', 120101, '120102', 120102,
         '110103', 110103, '120103', 120103, 
         '140303', 140303, '140313', 140313,
         '140312', 140312
        ].includes(this.billType)) {
          this.queryObj[this.billType].partnerId = this.form.partnerId;
        }
        if (newVal === '' ){
          this.queryObj[this.billType].partnerId = ''
        }
        // this.queryObj[this.billType].partnerId = this.form.partnerId;
        //console.log('this.queryObj', this.queryObj, this.partnerData, this.customerData);
        this.optionGoods = this.$select({
          key: "listGoods",
          option: {
            option: {
              noEcho: true,
              remoteBody: this.queryObj[this.billType],
              change: (propValue, row) => {
                if (propValue) {
                  let rows = JSON.parse(JSON.stringify(row));
                  const findIndex = this.form.details.findIndex(
                    (item) => item.goodsNo === propValue
                  );
                  rows.findIndex = findIndex;
                  if (['110101', 110101, '110102', 110102, '120101', 120101, '120102', 120102, '110103', 110103, '120103', 120103, '140303', 140303, '140312', 140312, '140312', 140312, '140313', 140313].includes(this.billType)) {
                    let text = ''
                    if (['140312', 140312, '140313', 140313, '120101', 120101, '120102', 120102 , '120103', 120103].includes(this.billType)) {
                      text = '添加单据之前明细之前, 请先选择客户'
                    } else if (['140303', 140303, '110101', 110101, '110102', 110102, '110103', 110103 ].includes(this.billType)) {
                      text = '添加单据之前明细之前, 请先选择供应商'
                    }
                    if(!this.form.partnerName || !this.form.partnerName) {
                      this.form.details = this.form.details.filter(x => x.goodsNo != propValue)
                      
                      if(this.form.details.length == 0) {
                        this.form.details = [{}]
                      }
                      this.$message.warning(text)
                      return
                    } 
                  }
                  
                  
                  
                  // let rows = JSON.parse(JSON.stringify(row));
                  // rows.findIndex = findIndex;
                  this.getSelectGoods("getSelectGoods", [rows]);
                } else {
                  this.$nextTick(() => {
                    //console.log("11");
                  });
                }
              },
              tableChange: (propValue, row) => {
                if (['140312', 140312, '110101', 110101, '110102', 110102, '120101', 120101, '120102', 120102, '110103', 110103, '120103', 120103, '140303', 140303, '140313', 140313].includes(this.billType)) {
                  let text = ''
                  if (['140312', 140312, '140313', 140313, '120101', 120101, '120102', 120102 , '120103', 120103].includes(this.billType)) {
                    text = '添加单据之前明细之前, 请先选择客户'
                  } else if (['140303', 140303, '110101', 110101, '110102', 110102, '110103', 110103 ].includes(this.billType)) {
                    text = '添加单据之前明细之前, 请先选择供应商'
                  }
                  if(!this.form.partnerName || !this.form.partnerName) {
                    this.$message.warning(text)
                    return
                  } 
                }
                this.getSelectGoods("getSelectGoods", row.check);
              },
              buttons: [
                {
                  type: "more",
                  option: {
                    title: "选择商品",
                    width: 1250,
                    type: "TreeAndTable",
                    formData: this.$dialog({
                      key: "goods",
                      option: {
                        // treeApi: undefined,
                        queryParams: this.queryObj[this.billType],
                        isSupplier:(this.billType=='110101'||this.billType=='110102'||this.billType=='110103'||this.billType=='140303')? true : false ,
                        table: {
                          ...this.$dialog({ key: "goods" }).table,
                        },
                      },
                    }),
                  },
                },
              ],
            },
          },
        }).option;
      },
      immediate: true,
    },
    //侦听是否开启关闭按钮
    "form.billStatus": {
      handler(newVal) {
        if (this.form.billStatus === "2") {
          //新增按钮
          this.showAdd = true;

          //打开关闭按钮
          this.single = false;
          //禁用输入按钮、保存按钮
          this.disabled = true;
          //打开审核按钮
          this.showAudit = false;
        } else if (this.form.billStatus === "3") {
          //新增按钮
          this.showAdd = false;
          //禁用关闭按钮
          this.single = true;
          //禁用输入按钮、保存按钮
          this.disabled = true;
          //禁用审核按钮
          this.showAudit = true;
        } else if (this.form.billStatus === "0") {
          //新增按钮
          this.showAdd = false;
          //打开输入按钮、保存按钮
          this.disabled = false;
          //禁用关闭按钮
          this.single = true;

          //禁用审核按钮
          this.showAudit = false;
        } else if (this.form.billStatus === "4") {
          //新增按钮
          this.showAdd = false;

          //禁用关闭按钮
          this.single = true;
          //禁用输入按钮、保存按钮
          this.disabled = true;
          //禁用审核按钮
          this.showAudit = true;
        } else if (this.form.billStatus === "") {
          //新增按钮
          this.showAdd = false;
          //禁用审核按钮
          this.showAudit = true;
          //禁用关闭按钮
          this.single = true;
          //打开输入按钮、保存按钮
          this.disabled = false;
        }
        if(this.disabled){
          this.gridColumns.forEach(item=>{
            item.disabled = true;
          })
        }else{
          this.gridColumns.forEach(item=>{
            item.disabled = false;
          })
          //console.log(this.gridColumnsCopy,'22222222222222222222222222222222222222')
          //this.gridColumns = arrayObjectCopy(this.gridColumnsCopy);
        }
      },
      immediate: true,
    },
    optionGoods(val){
      if(val){
        this.gridColumns.forEach(item=>{
          if(item.fieldName=='goodsNo'){
            item.options = val;
          }
        })
      }
    },
    'form.details':{
      handler(val){
        if(val&&val.length>0&&this.$refs.myTable){
          //单据源字段根据billType赋值
          val.forEach(item=>{
            if(item.sourceBillDetailId){
              if(this.billType=='110103'){
                item.billSourceOrder='采购入库单'
              }else if(this.billType=='110102'){
                item.billSourceOrder='采购订单'
              }else if(this.billType=='120103'){
                item.billSourceOrder='销售出库单'
              }else if(this.billType=='120102'){
                item.billSourceOrder='销售订单'
              }else if(this.billType=='140312'){
                item.billSourceOrder='门店销售单'
              }
            }
          })
          this.$refs.myTable.form.details= val||[];
          //console.log(val,this.$refs.myTable.form.details,'sdfsdfsdfsdfsdfsdf');
        }
      },
      immediate:true
    },
  },
  deactivated() {
    this.reset();
    this.disabled = false;
    this.auditOrder = false;
    this.stopOrder = false;
    this.noAuditOrder = false;
    this.deleteOrder = false;
    this.getCurTime();
    this.getNewBillNo(this.form, "billNo", Number(this.TypeBill));
  },
  activated() {
    this.handleCalcGridColumn(this.billType,'activated');
    if (this.keepStatus) return;
    if (this.$route.query.type === "Update") {
      if (this.$route.query.billId) {
        this.billId = this.$route.query.billId;
        if (this.urlDetail) {
          this.getBillDetail(true);
        }
      }
    } else {
      this.getCurTime();
    }
  },
  created() {
    //this.handleCanKeyTableColumnList();
    // this.getBasicData('detail', this.getCusSupp)
    //console.log(this.billType, "billType", this.form.partnerName, this.form.partnerId);
    // let queryObj = {
    //   110101: { isEnablePurchase: true, searchType: 1, excludeGoodsTypes: [-1], partnerId: this.form.partnerId},
    //   110102: { isEnablePurchase: true, excludeGoodsTypes: [-1] },
    //   110103: { isEnablePurchase: true, excludeGoodsTypes: [-1] },
    //   120101: { isEnableSale: true, excludeGoodsTypes: [-1] },
    //   140313: { isEnableSale: true, excludeGoodsTypes: [-1] },
    //   120102: { isEnableSale: true, excludeGoodsTypes: [-1] },
    //   120103: { isEnableSale: true, excludeGoodsTypes: [-1] },
    //   140303: { isEnablePurchase: true, excludeGoodsTypes: [-1] },
    //   140312: { isEnableSale: true, excludeGoodsTypes: [-1] },
    // };
    // console.log(queryObj[this.billType], "156465");
    this.optionGoods = this.$select({
      key: "listGoods",
      option: {
        option: {
          noEcho: true,
          remoteBody: this.queryObj[this.billType],
          change: (propValue, row) => {
            if (propValue) {
              let rows = JSON.parse(JSON.stringify(row));
              const findIndex = this.form.details.findIndex(
                (item) => item.goodsNo === propValue
              );
              rows.findIndex = findIndex;
              if (['110101', 110101, '110102', 110102, '120101', 120101, '120102', 120102, '110103', 110103, '120103', 120103, '140303', 140303, '140312', 140312, '140313', 140313].includes(this.billType)) {
                let text = ''
                if (['140312', 140312, '140313', 140313, '120101', 120101, '120102', 120102 , '120103', 120103].includes(this.billType)) {
                  text = '添加单据之前明细之前, 请先选择客户'
                } else if (['140303', 140303, '110101', 110101, '110102', 110102, '110103', 110103 ].includes(this.billType)) {
                  text = '添加单据之前明细之前, 请先选择供应商'
                }
                if(!this.form.partnerName || !this.form.partnerName) {
                  this.form.details = this.form.details.filter(x => x.goodsNo != propValue)
                  
                  if(this.form.details.length == 0) {
                    this.form.details = [{}]
                  }
                  this.$message.warning(text)
                  return
                } 
              }
              
              this.getSelectGoods("getSelectGoods", [rows]);
              // let rows = JSON.parse(JSON.stringify(row));
              // rows.findIndex = findIndex;
            } else {
              this.$nextTick(() => {
                //console.log("11");
              });
            }
          },
          tableChange: (propValue, row) => {
            if (['110101', 110101, '110102', 110102, '120101', 120101, '120102', 120102, '110103', 110103, '120103', 120103, '140303', 140303, '140312', 140312, '140313', 140313].includes(this.billType)) {
                let text = ''
                if (['140312', 140312, '140313', 140313, '120101', 120101, '120102', 120102 , '120103', 120103].includes(this.billType)) {
                  text = '添加单据之前明细之前, 请先选择客户'
                } else if (['140303', 140303, '110101', 110101, '110102', 110102, '110103', 110103 ].includes(this.billType)) {
                  text = '添加单据之前明细之前, 请先选择供应商'
                }
                if(!this.form.partnerId || !this.form.partnerName) {
                  this.$message.warning(text)
                  return
                } 
              }
            this.getSelectGoods("getSelectGoods", row.check);
          },
          buttons: [
            {
              type: "more",
              option: {
                title: "选择商品",
                width: 1250,
                type: "TreeAndTable",
                formData: this.$dialog({
                  key: "goods",
                  option: {
                    // treeApi: undefined,
                    queryParams: this.queryObj[this.billType],
                    isSupplier:(this.billType=='110101'||this.billType=='110102'||this.billType=='110103'||this.billType=='140303')? true : false ,
                    table: {
                      ...this.$dialog({ key: "goods" }).table,
                    },
                  },
                }),
              },
            },
          ],
        },
      },
    }).option;
  },
  beforeDestroy() {
    //页面销毁移除监听表格滚动条事件
    this.destroyScroll();
  },
  mounted() {
    this.getCurTime();
    this.scroll(); // 监听表格滚动条滚动事件
  },
  computed: {
    isUpdate() {
      return this.$route.query.type === "Update";
    },
    //单据金额
    billMoney() {
      const empty = "00:000";
      if (!this.form.details.length) return empty;
      let data = this.form.details.filter((i) => {
        return i.unitMoney;
      });
      let data1 = this.form.details.filter((x) => {
        return x.attachMoney;
      });
      let res = data.reduce((prev, curr) => {
        return prev + Number(curr.unitMoney);
      }, 0);
      if (!(data1.length === 0)) {
        let res1 = data1.reduce((prev, curr) => {
          return prev + Number(curr.attachMoney);
        }, 0);
        res = res + res1;
      }
      const total = res ? res.toFixed(this.decimalNum) : empty;
      this.form.billMoney = total;
      return total;
    },
  },
  methods: {
    //处理可以键盘导航的表格列详情数据
    handleCanKeyTableColumnList(){
      // this.canKeyTableColumnList.forEach(item=>{
      //   item.columnindex--;
      // })
      // console.log(this.canKeyTableColumnList,'is here')
      // return;
      let isHasProduceDate = false;//表格列是否有生产日期
      let isHasStore = false;//表格列是否有仓库
      if(['110102', '140303'].includes(this.billType)){
        isHasProduceDate = true;
      }
      if(!['140303', '140307', '140312', '140313'].includes(this.billType)){
        isHasStore = true;
      }
      if(!isHasProduceDate){//没有生产日期
        this.canKeyTableColumnList.forEach((item,index)=>{
          if(index>1){
            this.$set(item,'columnindex',item.columnindex-1);
          }
          if(item.key=='produceDate'){
            this.$set(this.canKeyTableColumnList[index],'isDeleted',true)
          }
        })
      }
      if(!isHasStore){//没有仓库
        this.canKeyTableColumnList.forEach((item,index)=>{
          if(index>2){
            this.$set(item,'columnindex',item.columnindex-1);
          }
          if(item.key=='storeId'){
            this.$set(this.canKeyTableColumnList[index],'isDeleted',true)
          }
        })
      }
      //计算备注的列数
      this.canKeyTableColumnList[this.canKeyTableColumnList.length-1].columnindex=  
      this.canKeyTableColumnList[this.canKeyTableColumnList.length-2].columnindex+this.tableBillSourceCout+this.tableSpecialCount;
      //console.log(this.canKeyTableColumnList)
    },
    //不同单据配置不同列信息
    handleCalcGridColumn(val,info){
      if(!['110102', '140303'].includes(val)){
        this.originColumns = this.originColumns.filter(item=>item.fieldName!='produceDate');
      }
      if(['140303', '140307', '140312', '140313'].includes(val)){
        this.originColumns = this.originColumns.filter(item=>item.fieldName!='storeId');
      }
      if(['110101','120101','140313'].includes(val)){
        this.originColumns = this.originColumns.filter(item=>item.fieldName!='importUnitQty');
      }
      if(!['140313','120101'].includes(val)){
        this.originColumns = this.originColumns.filter(item=>item.fieldName!='priceCaseName');
      }
      if(!['110101','120101','140313'].includes(val)){
        this.originColumns = this.originColumns.filter(item=>item.fieldName!='priceCaseName');
      }
      if(['110101','120101','140313'].includes(val)){
        this.originColumns = this.originColumns.filter(item=>item.fieldName!='billSourceOrder');
      }
      this.originColumns.forEach(item=>{
        if(item.fieldName=='importUnitQty'){
          let result = '';
          if(val=='110103'||val=='120103'){
            result='可退数量';
          }
          if(val=='110102'||val=='140303'){
            result='应收数量';
          }
          if(val=='120102'||val=='140312'){
            result='应出数量';
          }
          item.fieldCaption = result;
          item.fieldCustomCaption=result;
        }
      })
      this.originColumnsCopy = arrayObjectCopy(this.originColumns);
      this.getColumnConfigDetail();
    },
    changeSaleEmployee(val) {
      if (this.form.billDate) {
        let dateTime = new Date(this.form.billDate);
        let settlementTermDays = this.saleEmployeeData.filter(
          (v) => v.settlementTermId == val
        )[0].settlementTermDays;
        if (settlementTermDays) {
          let settlementDate = new Date(dateTime);
          settlementDate.setDate(dateTime.getDate() + settlementTermDays);
          let time = `${settlementDate.getFullYear()}-${
            settlementDate.getMonth() + 1
          }-${settlementDate.getDate()}`;
          this.form.settlementDate = time;
        }
      }
    },
    handleSelectionChange(selection) {
      this.ids = selection
        .map((item) => item.goodsId)
        .filter((ite) => typeof ite !== "undefined"); //单据id
      this.multiple = !this.ids.length;
    },
    disableCheckbox(row) {
      return row.isDelete != 0;
    },
    //打开弹窗
    getDistribution() {
      if (this.multiple) return this.$message.warning("请选择商品");
      this.dialogOptions = {
        title: "选择仓库",
        width: 1250,
        show: true,
        type: "TreeAndTable",
        formData:this.$dialog({ key: "store" })
      }
    },
    getSelectGoods(type, row) {
      if (
        [
          "110101",
          "110102",
          "110103",
          "120101",
          "140313",
          "120102",
          "120103",
          "140303",
          "140312",
        ].includes(this.billType)
      ) {
        this.getGoods(row);
      }
    },
    handleTableColumnConfig(){
      let tableData = arrayObjectCopy(this.gridColumns);
      let originData = arrayObjectCopy(this.originColumnsCopy);
      this.dialogOptions = {
        title: "列表配置",
        width: 800,
        show: true,
        type: "tableColumnConfig",
        data:{
          tableData,
          originData
        }
      }
    },
    async handleEvent(type,row) {
      switch(type){
        case "dialogChange":
          if (row.type === "TreeAndTable"){
            //批量修改仓库
            this.form.details = this.form.details.map((item) => ({
              ...item,
              storeId: this.ids.includes(item.goodsId)
                ? this.dialogOptions.formData.table.check[0].storeId
                : item.storeId,
              hoverRow: this.ids.includes(item.goodsId) ? true : false,
            }));
          }else if(row.type=='tableColumnConfig'){
            //修改列配置
            this.handleSaveColumnConfig(row.data.tableData);
          }
          break;
          case 'dialogInit':
            if(row.type=='tableColumnConfig'){
              //console.log(row)
              this.handleSaveColumnConfig(row.data.originData);
            }
            break;
        default:
          break;
      }
    },
    async handleAdd() {
      //console.log(this.form.billStatus, "form.billStatus ", this.billType);
      this.form = {
        deptId: undefined, // 门店ID
        //单据id
        billId: undefined,
        //制单日期
        billDate: undefined,
        //单据编号
        billNo: undefined,
        //供应商
        partnerId: undefined,
        //订单仓库
        storeId: undefined,
        //仓库(入库)
        inStoreId: undefined,
        //仓库(出库)
        outStoreId: undefined,
        //采购员
        purEmployeeId: undefined,
        //单据状态
        billStatus: "",
        //制单人
        createEmployeeId: undefined,
        //审核人
        auditEmployeeId: undefined,
        //审核日期
        auditTime: undefined,
        //单据金额
        billMoney: "00:000",
        //备注
        billRemark: undefined,
        //单据表格-订单明细
        details: [{}],
        //上传的附件
        fileItems: [],
        //收货地址
        storeAddress: {
          contactMan: undefined,
          contactTel: undefined,
          regionId: undefined,
          addressDetail: undefined,
        },
        //收货地址拼接
        storeAddressJoin: undefined,
      };
      const res = await getBillNo(this.billType);
      const data = await getNewDate();
      const data1 = await getNewDate(1);

      this.form.billNo = res;
      this.form.billDate = data;
      this.form.arrivalDate = data1;
    },
    handleInput2(e) {
      e.target.value = e.target.value.match(/^\d*(\.?\d{0,8})/g)[0] || "";
    },
    //退出
    getQuit() {
      this.$store.state.tagsView.visitedViews.splice(
        this.$store.state.tagsView.visitedViews.findIndex(
          (item) => item.path === this.$route.path
        ),
        1
      );
      history.back();
    },
    callUnitTaxMoney(row) {
      if (row.taxRate) {
        return this.$options.filters.numFilterFun(
          row.unitPrice * row.unitQty * (row.taxRate * 0.01)
        );
      } else {
        return 0;
      }
    },
    calUnitPriceTaxMoney(row) {
      return this.$options.filters.numFilter(row.unitTaxPrice * row.unitQty);
    },
    //计算生产日期 + 保质期天数 = 到期日
    computingTime(row) {
      if (row.produceDate && row.qualityDays) {
      }
    },
    //获取自定义搜索框选中的id  (供应商)
    getDiyselectId,
    //根据单据类型更改api
    changeBillDetailType,
    //格式化金额保留n位小数
    showFormatting,
    //获取附件上传的信息数据
    getFileItemsData,
    // 删除附件
    delFileItems,
    //表格滚动条位置
    //监听数据变化失去焦点时  滚动条的位置
    watchScroll,
    //监听滚动事件
    scroll,
    //移除监听滚动事件
    destroyScroll,
    // 获取滚动的宽度
    scrollEvent,
    // 数据刷新后设置滚动条当前滚动的位置
    savescroll,
    //获取全部行政地区事件
    getRegion,
    //设置收货地址
    setAddress,
    // 选中的仓库获取收货地址
    setAddressInfo,
    //获取收货地址
    getAddress,
    //收货地址省市区改变时获取省市区id
    getAddressId,
    //获取选中的供应商
    getSupplier,
    //获取选中的仓库
    getStore,
    //修改/编辑收货地址对话框
    saveAddress,
    //新增时获取当前时间
    getCurTime,
    //新增时获取单据编号
    getNewBillNo,
    //计算采购明细表格单价,含税价,税率，税额 根据两个值的变化自动计算
    handleEdit,
    //单价根据单位改变并显示保留3位小数
    changeUnitPrice,
    //订单明细表格合计
    getSummaries,
    //表格单元格回车事件
    // keyup,
    //获取下拉框数据
    getBasicData,
    //选择商品方法
    // openSelectGoods,
    //引入源单按钮方法
    selectBills,
    //获取引入源单全部数据
    getSelectBills,
    //表单重置
    reset,
    //初始化收货地址表单
    resetAddress,
    //表格增加/减少一行方法
    row,
    //新增单据
    AddBill,
    /** 提交按钮 */
    submitForm,
    //表格行hover时,显示操作加减号
    cellMouseEnter(row) {
      let eleIndex = this.form.details.indexOf(row);
      this.form.details[eleIndex].hoverRow = true;
      this.tableCellLabel = "操作";
    },
    //表格行离开hover时,不显示操作加减号,显示序号
    cellMouseLeave() {
      // 移除hover的事件
      for (let index = 0; index < this.form.details.length; index++) {
        const element = this.form.details[index];
        element["hoverRow"] = false;
      }
      this.tableCellLabel = "序号";
    },
    async changeBillStatus(name, bool) {
      try {
        await this.$confirm(`确定${name}该单据, 是否继续?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "success",
        });
        if (name === "审核") {
          if (this.form.billStatus != "0") {
            this.$message.error("此单据不是未审核的状态哦~");
            return;
          }
          await this.submitForm();
          let response = await updateInstoreStatus(
            [this.form.billId],
            "2",
            this.urlBillStatus,
            this.TypeBill
          );
          this.form.billStatus = response.data.billStatus;
          if (bool) {
            //有收货地址才进入  初始化收货地址
            if (
              response.data.storeAddress === null ||
              response.data.storeAddress === undefined
            ) {
              response.data.storeAddress = this.resetAddress();
            }
            this.getAddress();
          }

          this.disabled = true; //是否禁用
          this.auditOrder = this.form.billStatus; //审核图标
          this.noAuditOrder = false; //未审核图标
        } else if (name === "反审核") {
          let response = await updateInstoreStatus(
            [this.form.billId],
            "0",
            this.urlBillStatus,
            this.TypeBill
          );
          this.form.billStatus = response.data.billStatus;
          if (bool) {
            //有收货地址才进入   初始化收货地址
            if (
              response.data.storeAddress === null ||
              response.data.storeAddress === undefined
            ) {
              response.data.storeAddress = this.resetAddress();
            }
            this.getAddress();
          }
          this.disabled = false;
          this.auditOrder = false;
          this.noAuditOrder = true;
        } else if (name === "关闭") {
          //console.log("审核8 == ", this.auditOrder);
          updateInstoreStatus(
            [this.form.billId],
            "4",
            this.urlBillStatus,
            this.TypeBill
          ).then((response) => {
            this.form = response.data;
            if (bool) {
              //有收货地址才进入   初始化收货地址
              if (
                response.data.storeAddress === null ||
                response.data.storeAddress === undefined
              ) {
                response.data.storeAddress = this.resetAddress();
              }
              this.getAddress();
            }
            this.disabled = true;
            this.showAudit = true;
            this.stopOrder = true;
          });
        }
        if(this.disabled){
          this.gridColumns.forEach(item=>{
            item.disabled = true;
          })
        }else{
          this.gridColumns.forEach(item=>{
            item.disabled = false;
          })
        }
        //先备份数据
        if(this.form.details.length>0&&this.form.details[0].goodsNo){
            this.formDetailsCopy=JSON.parse(JSON.stringify(this.form.details));
        }
        //刷新组件
        this.tableKey = Date.now();
        setTimeout(()=>{
          //重新给表格赋值
          this.$refs.myTable.form.details =JSON.parse(JSON.stringify(this.formDetailsCopy)); 
        },100)
        //console.log(this.gridColumnsCopy,this.gridColumns,'审核操作以后')
        // 弹出提示
        this.$message.success(`${name}单据成功`);
      } catch {}
    },
    //表格数据更新回调
    handleSyncTableData(val){
      this.form.details = val;
    },
    //表格数据选中回调
    handleTableSelectChange(e,scope,item){
      //规格单位切换时，要同时变化
      if(item.fieldName=='unitId'){
        this.changeUnitPrice(scope.row.unitId,scope.row.units,scope.rowIndex,true)
        this.form.details[scope.rowIndex]['goodsSpec'] = scope.row.unitId;
      }
      if(item.fieldName=='goodsSpec'){
        this.changeUnitPrice(scope.row.goodsSpec,scope.row.units,scope.rowIndex,true)
        this.form.details[scope.rowIndex]['unitId'] = scope.row.goodsSpec;
      }
    },
    //表格文本框失焦回调
    handleTableBlur(e,scope,item){
      if(item.fieldName=='unitPrice'||item.fieldName=='unitTaxPrice'||item.fieldName=='taxRate'||item.fieldName=='unitPriceTaxMoney'){
        this.allFun(scope.row, item.fieldName, scope.rowIndex);
      }
      if(item.fieldName=='unitQty'){
        this.handleEdit(scope.row, item.fieldName, scope.rowIndex)
      }
    },
    //提交
    handleSubmit(flag){
      this.$refs.myTable.handleValidateTable(flag);
    },
    //表格数据验证回调
    handleTableCallback(data,flag){
      //console.log(data,flag);
      this.submitForm(flag);
    },
    //订单明细表格合计
    footerMethod(columns, data) {
      console.log(columns, data);
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        if (
          column.property === 'unitQty' || //数量
          column.property === 'unitTaxMoney' || //税额
          column.property === 'unitMoney' || //价税合计
          column.property === 'unitPriceTaxMoney' || //价税合计
          column.property === 'attachMoney'
        ) {
          //附加费
          const values = data.filter(
            item => !(item[`${column.property}`] === undefined)
          )
          const values2 = values.map(i => Number(i[`${column.property}`]))
          sums[index] = values2
            .reduce((prev, curr) => {
              return prev + curr
            }, 0)
            .toFixed(this.decimalNum)
        }
      })
      let arr = [];
      arr.push(sums);
      return arr;
      
    },
  },
};
</script>
<style lang="scss" scoped>
.instore-detail {
  background-color: #f3f3f3;

  ::v-deep .el-table--border {
    border: 1px solid #fff;
  }

  .card {
    min-height: 90vh;
    //顶部区域
    .bill-nav {
      width: 100%;

      .info {
        width: 80%;
        display: flex;
        flex-wrap: wrap;

        .searchItem {
          display: flex;
          align-items: center;
          text-align: left;

          &.Address {
            width: 50%;

            .itemAddress {
              width: 100%;
            }
          }

          .el-select,
          .treeselect,
          .el-input,
          ::v-deep.el-date-editor {
            width: 160px;
          }

          .label-name {
            min-width: 4vw;
            margin-right: 0.52vw;
            text-align: right;
            text-align-last: justify;
          }
        }
      }
    }

    //供应商下拉框
    ::v-deep.el-select-dropdown__list {
      min-width: 400px !important;
    }

    //未审核图标
    .nav-noAudit-icon {
      position: relative;

      &:after {
        content: "";
        position: absolute;
        height: 64px;
        width: 165px;
        background: url(~@/assets/images/noAudit.png) no-repeat;
        background-size: 100px 43px;
        top: calc(28% - 32px);
        right: calc(8% - 80px);
      }
    }

    //已审核图标
    .nav-audit-icon {
      position: relative;

      &:after {
        content: "";
        position: absolute;
        height: 64px;
        width: 165px;
        background: url(~@/assets/images/audit.png) no-repeat;
        background-size: 100px 43px;
        top: calc(28% - 32px);
        right: calc(8% - 80px);
      }
    }

    //已删除图标
    .nav-delete-icon {
      position: relative;

      &:after {
        content: "";
        position: absolute;
        height: 64px;
        width: 165px;
        background: url(~@/assets/images/delete.png) no-repeat;
        background-size: 100px 43px;
        top: calc(28% - 32px);
        right: calc(8% - 80px);
      }
    }

    //已关闭图标
    .nav-stop-icon {
      position: relative;

      &:after {
        content: "";
        position: absolute;
        height: 64px;
        width: 165px;
        background: url(~@/assets/images/stop.png) no-repeat;
        background-size: 100px 43px;
        top: calc(28% - 32px);
        right: calc(8% - 80px);
      }
    }

    // 按钮区域
    .instore-btn {
      display: flex;
      justify-content: space-between;

      .noAuditBtn {
        position: absolute;
        z-index: 2022;
        bottom: -30px;
        transform: translateX(70%);
        display: none;

        &.show {
          display: block;
        }
      }
    }

    // 表格区域
    .instore-table {
      ::v-deep.el-form-item {
        margin-bottom: 0;
      }

      ::v-deep.el-form-item__content {
        margin-left: 0 !important;
      }

      .table-icon {
        position: relative;

        &:after {
          content: "";
          position: absolute;
          height: 64px;
          width: 165px;
          background: url(~@/assets/images/audit.png) no-repeat;
          background-size: 164px 63px;
          top: calc(48% - 32px);
          left: calc(50% - 80px);
        }
      }
    }

    .instore-footer {
      display: flex;
      width: 100%;
      font-size: 14px;
      color: #606266;
      font-weight: 700;
      margin-top: 15px;
      padding-bottom: 49px;

      .left-accessory {
        display: flex;
        width: 50%;

        .btn {
          margin: 0 5px;
        }
      }

      .right-remark {
        width: 50%;
        display: flex;

        span {
          width: 10%;
        }

        .input {
          width: 90%;
        }
      }
    }
  }

  //底部区域
  .footer {
    height: 50px;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #fff;
    border-top: 1px solid #d6d8da;
    display: flex;

    .left {
      flex: 5;
    }

    .right {
      flex: 2;
      display: flex;

      .item {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0 10px;

        .label {
          font-size: 14px;
        }
      }
    }
  }
}

.ken-detail-form {
  padding: 40px 10px 0 10px;
}

// ::v-deep .el-form-item {
//   margin: 0;
//   padding: 0;
// }
//输入框原生属性
/**
 * 解决el-input设置类型为number时，中文输入法光标上移问题
 **/
::v-deep .el-input__inner {
  line-height: 1px !important;
}

/**
 * 解决el-input设置类型为number时，去掉输入框后面上下箭头
 **/
::v-deep input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::v-deep.el-table {
  ::v-deep.el-table__body-wrapper::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }
  ::v-deep.el-table__body-wrapper::-webkit-scrollbar-thnmb {
    background-color: #ccc !important;
    border-radius: 5px;
  }

  ::v-deep.el-table-fixed .el-table__body {
    padding-bottom: 12px;
  }
}
</style>
